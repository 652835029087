var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.disabled)?_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer",attrs:{"id":"kt_datatable_wrapper"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"dense":"","headers":_vm.headers,"items":_vm.users,"options":_vm.options,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.customSearch,"custom-sort":_vm.customSort},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center position-relative my-1"},[_c('span',{staticClass:"svg-icon svg-icon-1 position-absolute ms-6"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('rect',{attrs:{"opacity":"0.5","x":"17.0365","y":"15.1223","width":"8.15546","height":"2","rx":"1","transform":"rotate(45 17.0365 15.1223)","fill":"black"}}),_c('path',{attrs:{"d":"M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z","fill":"black"}})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-solid w-250px ps-14",attrs:{"type":"text","placeholder":"Search user","label":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]},proxy:true},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"symbol symbol-50 symbol-light-success"},[(item.image_src)?_c('img',{attrs:{"alt":"Pic","src":item.image_src}}):(item.name)?_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_vm._v(" "+_vm._s(item.name.charAt(0).toUpperCase())+" ")]):_vm._e()])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"text-gray-800 mb-1"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))]),_c('br'),_vm._v(_vm._s(item.user_email)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.company_name)),_c('br'),_vm._v(_vm._s(item.job_position)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.super_admin !== '1')?_c('b-dropdown',{attrs:{"text":"Actions"}},[(
                          item.super_admin !== '1' && item.activated === '1'
                        )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.blockUser(item)}}},[_vm._v(" Block ")]):_vm._e(),(
                          item.super_admin !== '1' && item.activated === '0'
                        )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.unblockUser(item)}}},[_vm._v(" Unblock ")]):_vm._e(),(item.super_admin !== '1')?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(item)}}},[_vm._v(" Delete ")]):_vm._e()],1):_vm._e()]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [(item.activated === '0')?_c('p',[_vm._v("User is blocked")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,false,305773641)})],1)])])])])])])]):_c('section',[_vm._v("Not autorized access")])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header flex-wrap py-3"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" All Users "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Manage User accounts and User roles.")])])]),_c('div',{staticClass:"card-toolbar"})])}]

export { render, staticRenderFns }